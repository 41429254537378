<template>
  <div class="main-head cs-flex-bc">
    <div class="head-left cs-flex-sc">
      <!-- <div class="head-logo">
        <img src="@/assets/imgs/layout/zhz-logo.png" alt="" />
      </div> -->
      <div class="cs-flex-col-cs cs-margin-left-10">
        <h2>民航专业工程安全质量信息管理系统</h2>
        <p>CIVIL AIRPORT ENGINEERING SAFETY MIS</p>
      </div>
    </div>
    <div class="head-right">
      <ul class="head-ul cs-flex">
        <li class="cs-flex-sc time">
          <div class="cs-padding-right-8">{{ ymdTime }}</div>
          <div>{{ hmsTime }}</div>
        </li>
        <li class="cs-flex-sc weather-wrap">
          <div class="weather icon-weather" :class="[usrinfo.weatherBg ? usrinfo.weatherBg : '']"></div>
          <div class="temperature">{{ usrinfo.temperature }}</div>
          <div class="temperature-unit">℃</div>
        </li>
        <li class="cs-flex-sc shu">
          <div class="shu-box"></div>
        </li>
        <li class="cs-flex-sc">
          <img :src="usrinfo.avatar || avatarImg" alt="" class="user" />
        </li>
        <li class="cs-flex-sc user-li" @click="projectShowFn">
          <div class="cs-flex-col-cs">
            <p class="user-name">{{ usrinfo.name }}</p>
            <p class="user-project">{{ usrinfo.project_name }}</p>
          </div>
          <div class="user-go"></div>
        </li>
      </ul>
      <div class="filter"></div>
    </div>
  </div>
  <div class="head-project-dia" ref="headProRef" v-if="projectShow" @mouseleave="hideMenu">
    <ul class="project-ul">
      <li v-for="item in projectList" :key="item.id" class="cs-flex-bc project-li" :class="{
          'project-li-cur': item.id == usrinfo.project_id,
          'project-li-hover': item.id == hoverProjectId,
        }" @click="changeProject(item.id)" @mouseover="showSubmenu(item.id)">
        <div class="cs-flex-sc">
          <img src="@/assets/imgs/layout/head-project.png" alt="" class="project-img" />
          <span>{{ item.name }}</span>
        </div>

        <img src="@/assets/imgs/layout/icon-right-hui.png" alt="" class="project-img-r" />
      </li>
    </ul>
    <div class="head-out-wrap cs-flex-sc" @click="logOut" @mouseover="hoverlogout">
      <img src="@/assets/imgs/layout/head-out.png" alt="" />
      <span>退出登录</span>
    </div>

    <div class="head-object-dia" v-if="objectShow" @mouseleave="hideSubmenu" :style="{ right: objCss.right + 'px' }">
      <div class="object-all-wrap cs-flex-sc">
        <div class="object-all cs-flex-sc" :class="{ 'object-cur': objectId == 0 }" @click="changeObject(0)">
          <div class="object-all-txt">全部</div>
        </div>
      </div>
      <ul class="object-item-wrap" v-if="objectList.length">
        <li v-for="itemObj in objectList" :key="itemObj.value" class="object-item cs-flex-sc" :class="{ 'object-cur': objectId == itemObj.value }" @click="changeObject(itemObj.value)">
          <img src="@/assets/imgs/layout/head-object.png" alt="" />
          <p>{{ itemObj.label }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  inject,
  ref,
  onBeforeUnmount,
  reactive,
  computed,
  onMounted,
} from "vue";
import { timeFormat } from "@darrytech/extends/utilities/dateutil";
export default defineComponent({
  name: "main-left",
  title: "工作区左侧菜单栏",
});
</script>
<script setup lang="ts">
import { Router, useRouter } from "vue-router";
import { UserInfo, userLogout, forceUserInfo } from "@/cores/stores/user";
import { useStorage, Storages } from "@/cores/stores/index";
import { useAssets } from "@/cores/jsexts/resource";
const router: Router = useRouter();
const usrinfo: UserInfo = useStorage(Storages.USERINFO);
const network: any = inject("$network");
const emits = defineEmits(["chageProject"]);
const avatarImg = useAssets("imgs/layout/h-user.png");
const headProRef = ref();
const timenow = ref();
const ymdTime = ref();
const hmsTime = ref();
const formData = reactive({
  project_id: "",
  object_id: 0,
}) as any;

const projectShow = ref(false);
const objectShow = ref(false);
const hoverProjectId = ref();
const objectId = ref(0) as any;
const projectList: any = computed(() => {
  return usrinfo.projects;
});
const objectList = ref([]) as any;
const objCss = reactive({ right: 215 });
const interval = setInterval(() => {
  timenow.value = timeFormat(new Date(), "yyyy/mm/dd hh:MM:ss");
  ymdTime.value = timenow.value.split(" ")[0];
  hmsTime.value = timenow.value.split(" ")[1];
}, 1000);
const getObjectList = (projectId: any) => {
  let projects = usrinfo.projects;
  let project3 = projects.find((v) => v.id == projectId)?.project3;
  objectList.value.slice(0, objectList.value.length);
  if (project3 && project3.length) {
    let objectListData =
      project3.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        };
      }) || [];
    objectList.value = [...objectListData];
    if (objectList.value.length) {
      objectShow.value = true;
    } else {
      objectShow.value = false;
    }
  } else {
    objectList.value = [];
    objectShow.value = false;
  }
  // network.dteqms.listProjectChild({ pid: projectId }).then((rsp: any) => {
  //   let objectListData =
  //     rsp.rows?.map((item: any) => {
  //       return {
  //         value: item.id,
  //         label: item.name,
  //       };
  //     }) || [];
  //   objectList.value = [...objectListData];
  //   if (objectList.value.length) {
  //     objectShow.value = true;
  //   } else {
  //     objectShow.value = false;
  //   }
  // });
};
const projectShowFn = () => {
  projectShow.value = true;
};
// 选择工程
const changeProject = (val: any) => {
  // getObjectList(val);
  objectId.value = 0;
  formData.object_id = 0;
  usrinfo.project_id = val;
  usrinfo.project_name = projectList.value.find((v) => v.id == val)?.name || "";
  usrinfo.project_item_id = 0;
  forceUserInfo(usrinfo);
  projectShow.value = false;
  objectShow.value = false;
  hoverProjectId.value = undefined;
  getGrants(val);
};
// hover工程
const showSubmenu = (projectId: any) => {
  hoverProjectId.value = projectId;
  objCss.right = headProRef.value.offsetWidth + 5;
  getObjectList(projectId);
};
// 选择项目
const changeObject = (val: any) => {
  objectId.value = val;
  usrinfo.project_id = hoverProjectId.value;
  usrinfo.project_name =
    projectList.value.find((v) => v.id == usrinfo.project_id)?.name || "";
  usrinfo.project_item_id = val;
  forceUserInfo(usrinfo);
  getGrants(hoverProjectId.value);
  projectShow.value = false;
  objectShow.value = false;
  hoverProjectId.value = undefined;
  // emits("chageProject",val)
};
const getGrants = (id: any) => {
  network.dteqms.commUserGrants({ project_id: id }).then((rsp: any) => {
    let userGrants = rsp.grants || [];
    let grantsMap = {};
    userGrants.forEach((item) => {
      if (item.includes("_")) {
        let fen = item.split("_");
        if (!grantsMap[fen[1]]) {
          grantsMap[fen[1]] = []; // 页面的权限
        }
        if (fen.length > 2) {
          if (fen[2] !== "M") {
            if (fen[2] == "P") {
              grantsMap[fen[1]].push(fen.slice(3).join("-"));
            } else {
              grantsMap[fen[1]].push(fen.slice(2).join("-"));
            }
          }
        } else {
          // grantsMap[fen[1]] = [];
        }
      }
    });

    usrinfo.userGrants = grantsMap;
    forceUserInfo(usrinfo);
    emits("chageProject", id);
    // router.push({ name: "indexPage" });
  });
};
const hideSubmenu = () => {
  objectShow.value = false;
  hoverProjectId.value = undefined;
};
const hideMenu = () => {
  if (!objectShow.value) {
    projectShow.value = false;
    objectShow.value = false;
  }
};
const hoverlogout = () => {
  objectShow.value = false;
  hoverProjectId.value = undefined;
};
const logOut = async () => {
  await network.dtsystem.logout();
  clearInterval(interval);
  userLogout(usrinfo);
  console.log("usrinfo.current_router==", usrinfo.current_router);
  router.replace({ name: "page-login" });
};

onMounted(() => {
  formData.project_id = usrinfo.project_id;
  objectId.value = usrinfo.project_item_id;
});
onBeforeUnmount(() => clearInterval(interval));
defineExpose({
  changeProject,
});
</script>

<style lang="scss">
.main-head {
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  flex: 0 0 70px;
  .head-left {
    height: 100%;
    padding-left: 26px;
    .head-logo {
      width: 56px;
      height: 36px;
      padding-right: 9px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h2 {
      font-size: 24px;
      color: #ffffff;
      font-family: "DingTalk-JinBuTi";
      font-weight: normal;
    }
    p {
      font-size: 8px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  .head-right {
    height: 100%;
    padding-right: 24px;
    position: relative;

    .filter {
      position: absolute;
      top: 0;
      left: -250px;
      width: 342px;
      height: 40px;
      border-radius: 50%;
      background: rgba(135, 49, 167, 0.7);
      filter: blur(30px);
    }
    .head-ul {
      height: 100%;
      color: #fff;
      li {
        height: 100%;
        padding-right: 18px;
        font-family: "FZLanTingHei-Regular";
        .icon-weather {
          width: 46px;
          height: 46px;
          padding-right: 0px;
          margin-bottom: 8px;
          background: url("@/assets/imgs/index/clear.png") no-repeat 0 0;
          background-size: 46px auto;
        }
        .icon-winds {
          @extend .icon-weather;
          background-image: url("@/assets/imgs/index/winds.png");
        }
        .icon-rain {
          @extend .icon-weather;
          background-image: url("@/assets/imgs/index/rain.png");
        }
        .icon-snow {
          @extend .icon-weather;
          background-image: url("@/assets/imgs/index/snow.png");
        }
        .icon-haze {
          @extend .icon-weather;
          background-image: url("@/assets/imgs/index/haze.png");
        }
        .icon-sandstorm {
          @extend .icon-weather;
          background-image: url("@/assets/imgs/index/sandstorm.png");
        }
        .icon-fog {
          @extend .icon-weather;
          background-image: url("@/assets/imgs/index/fog.png");
        }

        .icon-clear {
          @extend .icon-weather;
          background-image: url("@/assets/imgs/index/clear.png");
        }
        .icon-cloudy {
          @extend .icon-weather;
          background-image: url("@/assets/imgs/index/cloudy.png");
        }
        .icon-thundershower {
          @extend .icon-weather;
          background-image: url("@/assets/imgs/index/thundershower.png");
        }

        .temperature {
          font-family: "FZLanTingHei-Medium";
          font-size: 20px;
          line-height: 24px;
          color: #ffffff;
        }
        .temperature-unit {
          font-size: 14px;
          color: rgba(255, 255, 255, 0.75);
          padding-left: 2px;
        }
        .user {
          width: 44px;
          height: 44px;
          border-radius: 30px;
        }
        .user-name {
          font-size: 16px;
          color: #ffffff;
          padding-bottom: 3px;
        }
        .user-project {
          height: 22px;
          line-height: 22px;
          padding: 0 6px;
          font-size: 13px;
          color: #ffffff;
          background: rgba(0, 0, 0, 0.13);
          border-radius: 23px 23px 23px 23px;
        }
        .user-go {
          padding-left: 20px;
          width: 16px;
          height: 16px;
          background: url("@/assets/imgs/layout/icon-right.png") no-repeat 16px
            center;
          background-size: 16px 16px;
        }
      }
      .shu {
        padding-left: 13px;
        .shu-box {
          width: 2px;
          height: 16px;
          background: rgba(168, 199, 250, 0.5);
        }
      }
      .user-li {
        padding-right: 0;
        cursor: pointer;
      }
    }
  }
}
.head-project-dia {
  z-index: 999;
  position: fixed;
  right: 5px;
  top: 75px;
  min-width: 207px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 8px 8px;
  .project-li {
    padding: 14px 12px;
    margin-bottom: 6px;
    position: relative;
    cursor: pointer;
    &:hover,
    &.project-li-cur,
    &.project-li-hover {
      background: #e7f0ff;
      border-radius: 6px 6px 6px 6px;
    }
    &.project-li-cur ::before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 19px;
      background: #2c66ff;
      border-radius: 6px 6px 6px 6px;
      position: absolute;
      content: "";
    }
    .project-img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      color: #333333;
    }
    .project-img-r {
      width: 16px;
      height: 16px;
    }
  }
  .head-out-wrap {
    border-top: 1px solid #f2f2f2;
    padding: 19px 0 15px 19px;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      color: #333333;
    }
  }
}
.head-object-dia {
  z-index: 999;
  position: absolute;
  top: 0;
  right: 211px;
  width: auto;
  padding: 8px 0 6px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px 8px 8px 8px;
  .object-all-wrap {
    padding: 0 8px 6px;
    height: 46px;
    border-bottom: 1px solid #f2f2f2;
  }
  .object-all {
    height: 100%;
    width: 100%;

    position: relative;
    .object-all-txt {
      font-size: 14px;
      color: #333333;
      padding-left: 32px;
    }
  }
  .object-item-wrap {
    box-sizing: border-box;
    width: 100%;
    padding: 6px 8px 0px;
    .object-item {
      width: auto;
      min-width: 240px;
      padding: 15px 0;
      margin-bottom: 6px;
      position: relative;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        margin: 0 5px 0 11px;
      }
      p {
        font-size: 14px;
        color: #333333;
        white-space: nowrap;
        padding-right: 30px;
      }
    }
  }
  .object-all:hover,
  .object-item:hover {
    background: #e7f0ff;
    border-radius: 6px 6px 6px 6px;
  }
  .object-cur {
    background: #e7f0ff;
    border-radius: 6px 6px 6px 6px;
    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 19px;
      background: #2c66ff;
      border-radius: 6px 6px 6px 6px;
      content: "";
    }
    &::after {
      content: "";
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 13px;
      background: url("@/assets/imgs/layout/icon-check.png") no-repeat center 0;
      background-size: auto 13px;
    }
  }
}
</style>
